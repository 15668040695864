import * as React from "react"
import {useEffect, useRef, useState} from "react"

import Content from "../components/content"

import '../styles/global.scss'
import IconoAgenda from '../images/icono-agenda.png'
import VideoIntro from '../videos/agenda-intro.mp4'
import VideoOutro from '../videos/agenda-outro.mp4'


const AgendaPage = ({ data }) => {

  const [started, setStarted] = useState(false);
  const [introVideo, setIntroVideo] = useState(true);
  const [displayedEvent, setDisplayedEvent] = useState(null);
  const [outroVideo, setOutroVideo] = useState(false);
  const [eventos, setEventos] = useState([]);

  const startedRef = useRef(started);
  const displayedEventRef = useRef(displayedEvent);
  const videoOutroRef = useRef(null);
  const videoIntroRef = useRef(null);

  startedRef.current = started;
  displayedEventRef.current = displayedEvent;

  const introEnd = () => {
    setIntroVideo(false)
    setDisplayedEvent(0)
  }

  const BroadSignPlay = () => {
    if (!started) {
      videoIntroRef.current.play();
    }
  }


  useEffect(() => {
    if (!startedRef.current) {
      setInterval(() => {
        if (window.startGo && !startedRef.current) {
          window.startGo = false;
          setStarted(true);
          BroadSignPlay();
        }
      }, 500)
    }
  }, [started])

  useEffect(() => {
    const allEventos = data.agendas.edges.sort(() => 0.5 - Math.random()).map((edge) => {
      let eventoBackground = edge.node.imagen;
      if (eventoBackground === null) {
        const background = data.backgrounds.edges.filter((_background) => {
          return _background.node.categoria === edge.node.categoria;
        })
        eventoBackground = background[0].node.background;
      }
      let subtitulo1 = edge.node.inicio;
      if (edge.node.fin) {
        subtitulo1 = `De: ${subtitulo1}<br/>A: ${edge.node.fin}`
      }

      return {
        titulo: edge.node.titulo,
        subtitulo1: subtitulo1,
        subtitulo2: edge.node.localizacion,
        imagen: eventoBackground
      }
    });

    const _eventos = allEventos.slice(0, 3);
    setEventos(_eventos);
  }, [])

  useEffect(() => {
    let interval;
    if (!introVideo && !outroVideo) {
      interval = setInterval(() => {
        let currDisplayedEvent = displayedEventRef.current;
        //console.log('transition', currDisplayedEvent)
        if (currDisplayedEvent + 1 < eventos.length) {
          setDisplayedEvent(currDisplayedEvent => currDisplayedEvent + 1)
        } else {
          setOutroVideo(true)
          setDisplayedEvent(null)
          videoOutroRef.current.play();
        }
      }, (18000 / eventos.length))
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [introVideo, outroVideo]);

  return (
    <main className="main agenda">
      <video ref={videoIntroRef} style={{opacity: introVideo ? 1 : 0}} src={VideoIntro} autoPlay={false} controls={false} muted={true} onEnded={() => {introEnd()}}/>

      <section className={`header-container flex-set flex--row flex--align-items-center flex--content-center ${introVideo ? '' : 'slideInDown'}`}>
        <img src={IconoAgenda} className="header-icon" alt=""/>
        <span className="header-text text-uppercase">agenda</span>
      </section>

      {eventos.map((evento, index) =>
        <Content item={evento} show={displayedEventRef.current === index && !introVideo && !outroVideo} backgroundUplift={true} key={index}/>
      )}

      <video ref={videoOutroRef} style={{opacity: outroVideo ? 1 : 0}} src={VideoOutro} autoPlay={false} controls={false} muted={true}/>

    </main>
  )
}

export const pageQuery = graphql`  
  {
    agendas: allStrapiAgendas {
      edges {
        node {
          id
          categoria
          fin
          titulo
          localizacion
          inicio
          imagen {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    backgrounds: allStrapiAgendasBackgrounds {
      edges {
        node {
          background {
            localFile {
              publicURL
            }
          }
          categoria
        }
      }
    }
  }
`

export default AgendaPage
